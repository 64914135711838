<template>
  <header>
    <Logo />

    <div>
      <button @click="navOpen" class="block lg:hidden">
        <img src="@/assets/images/icons/menu.svg" alt="icons" />
      </button>
    </div>
  </header>
</template>

<script>
import Logo from "@/components/app/Logo.vue";
export default {
  name: "StaffHeader",

  components: {
    Logo,
  },

  methods: {
    navOpen() {
      this.$store.commit("general/toggleNavBar");
    },
  },
};
</script>

<style scoped>
header {
  @apply flex justify-between px-4 lg:px-7 py-[1.0625rem] lg:py-4 fixed top-0 left-0 right-0;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  z-index: 100;
}
</style>
