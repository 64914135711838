<template>
  <router-link to="/" class="flex gap-1.5 items-center">
    <img
      :src="require(`@/assets/images/logos/${PROJECT_NAME}.png`)"
      :alt="`${APP_NAME} logo`"
      class="w-[2.375rem] h-[2.375rem] lg:w-[2.94rem] lg:h-[2.94rem]"
    />

    <h1 class="font-playfair font-bold text-xs lg:text-base uppercase">
      {{ APP_NAME }}<br />Government
    </h1>
  </router-link>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style scoped></style>
