<template>
  <div class="rounded-lg p-5">
    <div class="flex items-center justify-between mb-3">
      <div class="">
        <img
          src="@/assets/images/icons/wallet.svg"
          alt="icons"
          class="w-7 h-7"
        />
      </div>
      <div>
        <slot name="action"></slot>
      </div>
    </div>

    <div class="text-idBlued">
      <h5 class="font-medium text-sm uppercase">
        <slot name="title"></slot>
      </h5>

      <h1 class="text-base lg:text-lg font-bold">
        <spinner v-if="fetching" color="oneGreen" />
        <slot v-else name="value"></slot>
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmallBox",

  props: {
    fetching: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
