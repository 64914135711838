<template>
  <div
    id="bgImg"
    class="border border-gray-200 p-5 rounded-2xl mx-auto bg-no-repeat w-full bg-cover"
    style="max-width: 700px"
  >
    <div v-if="getting" class="flex justify-center h-[50vh]">
      <spinner class="w-20 h-20" color="oneGreen" />
    </div>

    <template v-else>
      <section class="flex flex-col items-center relative">
        <p
          class="absolute right-0 top-11 font-bold text-xs"
          style="color: #8d8d8d"
        >
          GEN 69
        </p>
        <img
          :src="require(`@/assets/images/logos/${PROJECT_NAME}.png`)"
          :alt="`${APP_NAME} logo`"
          class="w-12 h-12"
        />

        <h1 class="font-bold text-sm uppercase" style="color: #484848">
          {{ APP_NAME }} CIVIL SERVICE
        </h1>
        <h3 class="font-black uppercase" style="color: #4c5d65">
          NOTIFICATION OF APPOINTMENT
        </h3>
        <h6
          class="text-xs font-bold py-2 px-4"
          style="color: #787878; background-color: #f2f2f2"
        >
          TO BE PRESENTED IN TRIPLICATE: ORIGINAL TO THE OFFICER,<br />
          DUPLICATE TO THE ACCOUNTS BRANCH, TRIPLICATE TO FILE.
        </h6>
      </section>

      <table class="w-full mt-4">
        <tr>
          <td class="font-bold text-xs w-1/2">
            <span class="mr-2" style="color: #2fb053">OUR REF</span>
            <span style="color: #252a2f">________________</span>
          </td>
          <td
            class="font-bold text-xs w-1/2 text-right"
            style="text-justify: right"
          >
            <span class="mr-2" style="color: #252a2f">DATE</span>
            <span style="color: #5e6974">
              <!-- 30th December, 2023 -->
              {{ new Date() | moment("Do MMMM, YYYY") }}
            </span>
          </td>
        </tr>
      </table>

      <table
        class="w-full mt-2.5 border-separate"
        style="border-spacing: 0px 10px"
      >
        <tr class="w-full" style="font-size: 10px">
          <th class="w-1/3 text-left font-semibold" style="color: #5e6974">
            Name
          </th>
          <td
            class="text-left font-bold py-1.5 px-3 uppercase"
            style="background-color: #f2f9ff; color: #263238"
          >
            {{ staff.name }}
          </td>
        </tr>
        <tr class="w-full" style="font-size: 10px">
          <th class="w-1/3 text-left font-semibold" style="color: #5e6974">
            Verification Number
          </th>
          <td
            class="text-left font-bold py-1.5 px-3"
            style="background-color: #f2f9ff; color: #263238"
          >
            {{ staff.verificationCode ?? "N/A" }}
          </td>
        </tr>
        <tr class="w-full" style="font-size: 10px">
          <th class="w-1/3 text-left font-semibold" style="color: #5e6974">
            Address
          </th>
          <td
            class="text-left font-bold py-1.5 px-3 uppercase"
            style="background-color: #f2f9ff; color: #263238"
          >
            {{ staff.city }}, {{ staff.lga }} {{ staff.stateOfOrigin }}
          </td>
        </tr>
        <tr class="w-full" style="font-size: 10px">
          <th class="w-1/3 text-left font-semibold" style="color: #5e6974">
            Department
          </th>
          <td
            class="text-left font-bold py-1.5 px-3"
            style="background-color: #f2f9ff; color: #263238"
          >
            {{ staff.departmentName }}
          </td>
        </tr>
        <tr class="w-full">
          <td colspan="2" class="text-left font-bold py-1.5">
            <hr />
          </td>
        </tr>
        <tr class="w-full">
          <td
            colspan="2"
            class="text-left font-bold text-xs"
            style="color: #252a2f"
          >
            {{ staff.title }},
          </td>
        </tr>
        <tr class="w-full">
          <td
            colspan="2"
            class="text-left font-medium text-xs"
            style="color: #5e6974"
          >
            With reference to your acceptance of appointment on form Gen69.
            dated
          </td>
        </tr>
        <tr class="w-full" style="font-size: 10px">
          <th
            class="w-1/3 text-left font-semibold pl-3"
            style="
              color: #5e6974;
              border-left: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            "
          >
            Date
          </th>
          <td
            class="text-left font-bold py-1.5 px-3"
            style="
              background-color: #f6f6f6;
              color: #263238;
              border-right: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              outline: 1px solid #ffffff;
              outline-offset: -2px;
            "
          >
            {{ staff.dateOfAppointment | moment("Do MMMM, YYYY") }}
          </td>
        </tr>
        <tr class="w-full">
          <td
            colspan="2"
            class="text-left font-medium text-xs"
            style="color: #5e6974"
          >
            I have the honor to inform you that you are hereby appointed as
          </td>
        </tr>
        <tr class="w-full" style="font-size: 10px">
          <th
            class="w-1/3 text-left font-semibold pl-3"
            style="
              color: #5e6974;
              border-left: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            "
          >
            Position
          </th>
          <td
            class="text-left font-bold py-1.5 px-3"
            style="
              background-color: #f6f6f6;
              color: #263238;
              border-right: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              outline: 1px solid #ffffff;
              outline-offset: -2px;
            "
          >
            {{ staff.cadre }}
          </td>
        </tr>
        <tr class="w-full">
          <td colspan="2" class="text-left font-bold py-1.5">
            <hr />
          </td>
        </tr>
        <tr class="w-full">
          <td
            colspan="2"
            class="text-left font-bold text-xs"
            style="color: #5e6974"
          >
            2. The following conditions will apply:
          </td>
        </tr>
        <tr class="w-full" style="font-size: 10px">
          <th
            class="w-1/3 text-left font-semibold pl-3"
            style="
              color: #5e6974;
              border-left: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            "
          >
            Effective date of Appointment
          </th>
          <td
            class="text-left font-bold py-1.5 px-3"
            style="
              background-color: #f6f6f6;
              color: #263238;
              border-right: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              outline: 1px solid #ffffff;
              outline-offset: -2px;
            "
          >
            {{ staff.dateOfAppointment | moment("Do MMMM, YYYY") }}
          </td>
        </tr>
        <tr class="w-full" style="font-size: 10px">
          <th
            class="w-1/3 text-left font-semibold pl-3"
            style="
              color: #5e6974;
              border-left: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            "
          >
            Salary Scale
          </th>
          <td
            class="text-left font-bold py-1.5 px-3"
            style="
              background-color: #f6f6f6;
              color: #263238;
              border-right: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              outline: 1px solid #ffffff;
              outline-offset: -2px;
            "
          >
            {{ staff.salaryGradeLevel }}
          </td>
        </tr>
        <tr class="w-full" style="font-size: 10px">
          <th
            class="w-1/3 text-left font-semibold pl-3"
            style="
              color: #5e6974;
              border-left: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            "
          >
            Entry Point
          </th>
          <td
            class="text-left font-bold py-1.5 px-3"
            style="
              background-color: #f6f6f6;
              color: #263238;
              border-right: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              outline: 1px solid #ffffff;
              outline-offset: -2px;
            "
          >
            ------
          </td>
        </tr>
        <tr class="w-full" style="font-size: 10px">
          <th
            class="w-1/3 text-left font-semibold pl-3"
            style="
              color: #5e6974;
              border-left: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            "
          >
            Incremental Date
          </th>
          <td
            class="text-left font-bold py-1.5 px-3"
            style="
              background-color: #f6f6f6;
              color: #263238;
              border-right: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              outline: 1px solid #ffffff;
              outline-offset: -2px;
            "
          >
            -------
          </td>
        </tr>
        <tr class="w-full">
          <td
            colspan="2"
            class="text-left font-bold text-xs"
            style="color: #5e6974"
          >
            3. REPORTING DETAILS
          </td>
        </tr>
        <tr class="w-full" style="font-size: 10px">
          <th
            class="w-1/3 text-left font-semibold pl-3"
            style="
              color: #5e6974;
              border-left: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            "
          >
            You should report for duty to the
          </th>
          <td
            class="text-left font-bold py-1.5 px-3"
            style="
              background-color: #f6f6f6;
              color: #263238;
              border-right: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              outline: 1px solid #ffffff;
              outline-offset: -2px;
            "
          >
            Director, Administration
          </td>
        </tr>
        <tr class="w-full" style="font-size: 10px">
          <th
            class="w-1/3 text-left font-semibold pl-3"
            style="
              color: #5e6974;
              border-left: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            "
          >
            At
          </th>
          <td
            class="text-left font-bold py-1.5 px-3"
            style="
              background-color: #f6f6f6;
              color: #263238;
              border-right: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              outline: 1px solid #ffffff;
              outline-offset: -2px;
            "
          >
            {{ staff.mdaName }}
          </td>
        </tr>
        <tr class="w-full" style="font-size: 10px">
          <th
            class="w-1/3 text-left font-semibold pl-3"
            style="
              color: #5e6974;
              border-left: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            "
          >
            Not later than 7:30am on
          </th>
          <td
            class="text-left font-bold py-1.5 px-3"
            style="
              background-color: #f6f6f6;
              color: #263238;
              border-right: 0.8px solid #d6dbe6;
              border-top: 0.8px solid #d6dbe6;
              border-bottom: 0.8px solid #d6dbe6;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
              outline: 1px solid #ffffff;
              outline-offset: -2px;
            "
          >
            the next working day
          </td>
        </tr>
        <template v-if="staff.signatureStatus === 'signed'">
          <tr class="w-full">
            <td
              colspan="2"
              class="text-left font-medium text-xs"
              style="color: #5e6974"
            >
              4. Proceed to
              <a class="font-bold" :href="loginUrl">
                {{ loginUrl }}
              </a>
              to complete your online verification.
            </td>
          </tr>
          <tr class="w-full">
            <td
              colspan="2"
              class="text-left font-medium text-xs"
              style="color: #5e6974"
            >
              5. You will need the
              <span class="font-bold">verification number</span> provided on
              this appointment letter and your
              <span class="font-bold">Bank account number</span> to successfully
              register and complete your online verification.
            </td>
          </tr>

          <tr class="w-full">
            <td class="w-1/2 mt-3">
              <div style="width: 97px; height: 97px">
                <img :src="staff.qrCode" alt="qrcode" />
              </div>
              <div class="font-bold text-xs">
                <span style="color: #263238">Verification URL: </span>
                <a :href="staff.baseUrl" style="color: #5e6974">
                  {{ staff.baseUrl }}
                </a>
              </div>
            </td>
            <td class="w-1/2 mt-3">
              <img
                :src="staff.signedBySignature"
                alt="signature"
                width="100px"
                height="38px"
                class="object-fill mb-1 mx-auto"
              />

              <hr style="border: 0.6px solid #252a2f" />

              <div class="text-center">
                <h4 class="font-black text-xs">{{ staff.signedByName }}</h4>
                <p
                  class="italic font-medium"
                  style="color: #5e6974; font-size: 10px"
                >
                  {{ staff.signedByPosition }}
                </p>
              </div>
            </td>
          </tr>
        </template>

        <template v-else>
          <tr>
            <td colspan="2" class="text-center text-sm font-bold pt-3">
              This record is not yet signed, and it is not binding
            </td>
          </tr>
        </template>
      </table>
    </template>
  </div>
</template>

<script>
export default {
  name: "StaffGen69",

  props: {
    staffData: {
      type: Object,
      default: null,
      required: true,
    },
    getting: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    staff() {
      return this.staffData;
    },
    loginUrl() {
      return `${window.location.origin}/signup`;
    },
  },
};
</script>

<style scoped>
#bgImg {
  background-image: url("../../../assets/images/gen69_bg.png");
}
</style>
